import {useEffect , useState,useCallback,useRef} from 'react'
import axios from 'axios'
import {useSelector,useDispatch } from 'react-redux'
import { uiActions } from '../store/ui-slice';

import { FaTrashAlt ,FaTrashRestore } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import Modal from './../UI/Modal'
import DeletModel from '../components/Helper/DeleteModel';
import UnDeleteModel from '../components/Helper/UnDeleteModel';
import EditEngine from '../components/Engine/EditEngine';
import { notification  } from 'antd';

import SmallModal from './../UI/SmallModal';
import { FaSpinner } from "react-icons/fa";

import { CSVLink ,CSVDownload } from "react-csv";

let headers = [
  { label: "Make", key: "make" },
  { label: "Model", key: "model" },
  { label: "Fuel Type", key: "fuelType"},
  { label: "CC", key: "cc"},
  { label: "Body", key: "body"},
  { label: "Cyl", key: "cyl"},
  { label: "Years", key: "yaers"},
  { label: "Code", key: "code"},
  { label: "Cam", key: "cam"},
  { label: "Bore", key: "bore"},
  { label: "KW", key: "kw"},
];


let AllEngine=()=>{

    let [enginesList, setEnginesList] = useState([]);
    let [inputValue,setInputValue]=useState('');
    let [filteredList,setFilteredList]=useState([]);
    let [loading, setLoading] = useState(true);
    let [error, setError] = useState(null);
    let [showModel,setShowModel]=useState(false)
    let inputRef=useRef()


    let [selectItem,setSelectItem]=useState(null);
    let [editModal,setEditModal]=useState(false);

    let [restoreModel,setRestoreModel]=useState(false);

   

    let baseURL=useSelector(state=>state.ui.baseURL)
    let dispatch=useDispatch()

    let onClose=()=>{
      setShowModel(false)
     }
  
   

    let getAllEngine = useCallback(async () => {
      setLoading(true)
        setError(null);
        try {
          let response = await axios.get(baseURL+"engine/getAll");
         
          if (response.status !== 200) {
            throw new Error("Something Went Wrong Try Again");
          }
          if (response.data.code !== 200) {
            throw new Error(response.data.message);
          }
          // console.log('+++++++data ---',response.data)
          setEnginesList(response.data.engines);
          setFilteredList(response.data.engines);
          setLoading(false);
        } catch (error) {
          setLoading(false)
          setError(error.message);
        }
      }, []);


     
    
      useEffect(() => {
        dispatch(uiActions.updateHeading({heading:'Engines'}))
        getAllEngine();
      }, [getAllEngine]);


      const [api, contextHolder] = notification.useNotification();

      const openNotificationWithIcon = (type,msg,description) => {
       api[type]({
         message: msg,
         description:description,
       });
     };

     
      //  search Filter

      // let inputChnageHandler=(e)=>{
      //   setInputValue(e.target.value)
      // }


      //  Search Handler

      let searchHandler=()=>{
        let input=inputRef.current.value.toUpperCase()
        // const pets = ['cat', 'dog', 'bat'];

// console.log(pets.includes('cat'));
        let tempArray=enginesList.filter(item=>item.make.includes(input)||item.model.includes(input)||item.fuelType.includes(input)||item.cc.includes(input)||item.body.includes(input)||item.cyl.includes(input)||item.years.includes(input)||item.code.includes(input)||item.cam.includes(input)||item.bore.includes(input)||item.kw.includes(input))
        setFilteredList(tempArray);
      }
   
            //  Edit Modal
   
     let onCloseEditModal=()=>{
       setEditModal(false)
     }
          
          let   updateOBjectsArray=(obj,msg,type)=>{
      
           let indexOfObject=enginesList.findIndex(item=>item._id===obj._id)
           enginesList[indexOfObject]=obj;
           
           setEnginesList(enginesList);
           onCloseEditModal();
           openNotificationWithIcon(type,'',msg)
           
         }
   
         let onCloseRestore=()=>{
           setRestoreModel(false)
        }
   

return(
    <div>
        
        {contextHolder}
            {loading&&<SmallModal><FaSpinner  className='animate-spin text-primaryColor text-8xl'/></SmallModal>}
         {showModel&&<Modal><DeletModel id={selectItem._id} onClose={onClose} afterDeleteHandler={updateOBjectsArray} api={`${baseURL}engine/delete`}/></Modal>}
       {restoreModel&&<Modal><UnDeleteModel id={selectItem._id} onCloseRestore={onCloseRestore} afterDeleteHandler={updateOBjectsArray} api={`${baseURL}engine/delete`}/></Modal>}
        {editModal&&<Modal><EditEngine updateOBjectsArray={updateOBjectsArray} onClose={onCloseEditModal} item={selectItem}/></Modal>}
        <div className='flex space-x-4 my-2'>
        <input type="text" placeholder=' i.e xyz' ref={inputRef} className='outline-none border border-black p-1 rounded-md'/>
        <button className='btn' onClick={searchHandler}>Searchh</button>
        <button className='btn' onClick={()=>{setFilteredList(enginesList)}}>Show All</button>
        <CSVLink data={filteredList} className='btn' filename={"engines.csv"} headers={headers}>
  Export File
</CSVLink>
        </div>
        <table className='w-full table-fixed mt-3 text-left'>
            <thead className='shadow-lg'>
                <tr>
                  <th>Img</th>
                <th>make</th>
            <th>model</th>
            <th>fuelType</th>
            <th>CC</th>
            <th>Body</th>
            <th>Cyl</th>
            <th>Years</th>
            <th>code</th>
            <th>CAM</th>
            <th>Bore</th>
            <th>KW</th>
            <th colSpan={2} className="text-center">Action</th>
                </tr>
          
            </thead>
            <tbody>
                {filteredList&&filteredList.length>0&&filteredList.map(item=>{
                  return(
                    <tr>
                       <td><img src={'https://hashtechcarparts.s3.amazonaws.com/'+item.image+''} className="w-20 h-20 rounded-lg" alt={item.image}/></td>
                      <td>{item.make}</td>
                      <td>{item.model}</td>
                      <td>{item.fuelType}</td>
                      <td>{item.cc}</td>
                      <td>{item.body}</td>
                      <td>{item.cyl}</td>
                      <td>{item.years}</td>
                      <td>{item.code}</td>
                      <td>{item.cam}</td>
                      <td>{item.bore}</td>
                      <td>{item.kw}</td>
                      <td className='text-blue-500 text-lg'><FaEdit onClick={()=>{setSelectItem(item);setEditModal(true);}}/></td>
                      {item.isDeleted?<td className='text-blue-500 text-lg'><FaTrashRestore onClick={()=>{setSelectItem(item); setRestoreModel(true)}}/></td>: <td className='text-red-500 text-lg'><FaTrashAlt onClick={()=>{setSelectItem(item); setShowModel(true)}}/></td>}
                    </tr>
                  )
                })}
            </tbody>
        </table>
    </div>
)

}

export default AllEngine